export function getEnumText(array, value) {
  try {
    return array.find(key => key.value === value).text
  } catch(e) {
    return value;
  }
}

export const EnumShipmentType = { RETAIL: 0, CORPORATE: 1, MARKETPLACE: 2, ANYMARKETING: 3}
export const EnumShipmentTypeValues = [
  { value: EnumShipmentType.RETAIL, text: 'Varejo'},
  { value: EnumShipmentType.CORPORATE, text: 'Corporativo'},
  { value: EnumShipmentType.MARKETPLACE, text: 'Marketplace'},
  { value: EnumShipmentType.ANYMARKETING, text: 'Anymarketing'},
]

export const EnumStatusProduction = { NOVO: 0, PRODUZINDO: 1, FINALIZADO: 2, CANCELADO : 3}
export const EnumStatusProductionValues = [
  { value: EnumStatusProduction.NOVO, text: 'Novo'},
  { value: EnumStatusProduction.PRODUZINDO, text: 'Produzindo'},
  { value: EnumStatusProduction.FINALIZADO, text: 'Finalizado'},
  { value: EnumStatusProduction.CANCELADO, text: 'Cancelado'},
]

export const EnumShipmentGroupExpeditionStatus = { CONGELADO: 0, NOVO: 1, EMSEPARACAO: 2, PRONTOPARAENVIO: 3, FINALIZADO: 4, PENDENCIA: 5}
export const EnumShipmentGroupExpeditionStatusValues = [
  { value: EnumShipmentGroupExpeditionStatus.CONGELADO, text: 'Aguardando Produção'},
  { value: EnumShipmentGroupExpeditionStatus.NOVO, text: 'Novo'},
  { value: EnumShipmentGroupExpeditionStatus.EMSEPARACAO, text: 'Em Separação'},
  { value: EnumShipmentGroupExpeditionStatus.PRONTOPARAENVIO, text: 'Pronto para Envio'},
  { value: EnumShipmentGroupExpeditionStatus.FINALIZADO, text: 'Enviado'},
  { value: EnumShipmentGroupExpeditionStatus.PENDENCIA, text: 'Em Pendência'},
]

export const EnumShipmentChannel = { SITE: 0, B2W: 1, MELI: 2, REVENDEDOR: 3, REVENDEDORMELI: 4, REVENDEDORMKT: 5, CORPORATIVO: 6}
export const EnumShipmentChannelValues = [
  { value: EnumShipmentChannel.SITE, text: 'Site'},
  { value: EnumShipmentChannel.B2W, text: 'B2W'},
  { value: EnumShipmentChannel.MELI, text: 'Mercado Livre'},
  { value: EnumShipmentChannel.REVENDEDOR, text: 'Revendedor Site'},
  { value: EnumShipmentChannel.REVENDEDORMELI, text: 'Revendedor Meli'},
  { value: EnumShipmentChannel.REVENDEDORMKT, text: 'Revendedor Mkt'},
  { value: EnumShipmentChannel.CORPORATIVO, text: 'Corporativo'},
]

export const EnumShipmentStatus = { PENDENTE: 0, CONFIRMADO: 1, CANCELADO: 2}
export const EnumShipmentStatusValues = [
  { value: EnumShipmentStatus.PENDENTE, text: 'Pendente'},
  { value: EnumShipmentStatus.CONFIRMADO, text: 'Confirmado'},
  { value: EnumShipmentStatus.CANCELADO, text: 'Cancelado'},
]

export const EnumShipmentStatusSeparation = { PENDENTE: 0, CONFIRMADO: 1, CANCELADO: 2, SEPARANDO: 3}
export const EnumShipmentStatusSeparationValues = [
  { value: EnumShipmentStatusSeparation.PENDENTE, text: 'Pendente'},
  { value: EnumShipmentStatusSeparation.CONFIRMADO, text: 'Confirmado'},
  { value: EnumShipmentStatusSeparation.CANCELADO, text: 'Cancelado'},
  { value: EnumShipmentStatusSeparation.SEPARANDO, text: 'Separando'},
]

export const EnumShipmentStatusShipping = { PENDENTE: 0, CONFIRMADO: 1, CANCELADO: 2}
export const EnumShipmentStatusShippingValues = [
  { value: EnumShipmentStatusShipping.PENDENTE, text: 'Pronto p/ Envio'},
  { value: EnumShipmentStatusShipping.CONFIRMADO, text: 'Enviado'},
  { value: EnumShipmentStatusShipping.CANCELADO, text: 'Cancelado'},
]

export const EnumStockType = { DEVOLUCAO: 0, NORMAL: 1, RESERVA: 2, VENDA: 3, USARRESERVA: 4, CANCELARRESERVA: 5}
export const EnumStockTypeValues = [
  { value: EnumStockType.DEVOLUCAO, text: 'Devolução'},
  { value: EnumStockType.NORMAL, text: 'Entrada'},
  { value: EnumStockType.RESERVA, text: 'Reservado'},
  { value: EnumStockType.VENDA, text: 'Venda'},
  { value: EnumStockType.USARRESERVA, text: 'Retirada de Estoque'},
  { value: EnumStockType.CANCELARRESERVA, text: 'Cancelamento de Reserva'},
]

export const EnumStatusInvoice = { PENDING: 0, SUCCESS: 1, CANCELED: 2}
export const EnumStatusInvoiceValues = [
  { value: EnumStatusInvoice.PENDING, text: 'Pendente'},
  { value: EnumStatusInvoice.SUCCESS, text: 'Emitida'},
  { value: EnumStatusInvoice.CANCELED, text: 'Cancelado'},
]

export const EnumStatusControlInvoice = { PENDING: 0, ERROR: 1, WAITING: 2, SUCCESS: 3, CANCELED_PENDING: 4, CANCELED_WAITING: 5, CANCELED_SUCCESS: 6, AWAITING_SALE: 7}
export const EnumStatusControlInvoiceValues = [
  { value: EnumStatusControlInvoice.PENDING, text: 'Não Enviado'},
  { value: EnumStatusControlInvoice.ERROR, text: 'Erro ao Enviar'},
  { value: EnumStatusControlInvoice.WAITING, text: 'Enviado aguardando emissão'},
  { value: EnumStatusControlInvoice.SUCCESS, text: 'Emitido e sincronizado'},
  { value: EnumStatusControlInvoice.CANCELED_PENDING, text: 'Cancelamento Pendente'},
  { value: EnumStatusControlInvoice.CANCELED_WAITING, text: 'Aguardando cancelamento'},
  { value: EnumStatusControlInvoice.CANCELED_SUCCESS, text: 'Cancelado'},
  { value: EnumStatusControlInvoice.AWAITING_SALE, text: 'Aguardando Nota Fiscal de Venda'},
]

export const EnumDocumentType = { CPF: 0, CNPJ: 1,}
export const EnumDocumentTypeValues = [
  { value: EnumDocumentType.CPF, text: 'CPF'},
  { value: EnumDocumentType.CNPJ, text: 'CNPJ'},
]

export const EnumInvoiceTypeProduct = { IMPRESSA: 1, DIGITAL: 2, CURSO: 3}
export const EnumInvoiceTypeProductValues = [
  { value: EnumInvoiceTypeProduct.IMPRESSA, text: 'Produto Impresso'},
  { value: EnumInvoiceTypeProduct.DIGITAL, text: 'Produto Digital'},
  { value: EnumInvoiceTypeProduct.CURSO, text: 'Curso'},
]

export const EnumTypeFlag = { VISA: 1, MASTER: 2, AMEX: 3, SORO: 4, DINERS: 5, ELO: 6, HIPER: 7, AURA: 8, CABAL: 9, BOLETO: 0, PIX: 10}
export const EnumTypeFlagValues = [
  { value: EnumTypeFlag.VISA, text: 'Visa'},
  { value: EnumTypeFlag.MASTER, text: 'Mastercard'},
  { value: EnumTypeFlag.AMEX, text: 'America Express'},
  { value: EnumTypeFlag.SORO, text: 'Sorocred'},
  { value: EnumTypeFlag.DINERS, text: 'Diners'},
  { value: EnumTypeFlag.ELO, text: 'Elo'},
  { value: EnumTypeFlag.HIPER, text: 'Hipercard'},
  { value: EnumTypeFlag.AURA, text: 'Aura'},
  { value: EnumTypeFlag.CABAL, text: 'Cabal'},
  { value: EnumTypeFlag.BOLETO, text: 'Boleto Bancário'},
  { value: EnumTypeFlag.PIX, text: 'PIX'},
]

export const EnumTypePaymentMethod = {BOLETO: 3, CARTAO: 4, OUTROS: 2, PIX: 5}
export const EnumTypePaymentMethodValues = [
  { value: EnumTypePaymentMethod.BOLETO, text: 'Boleto Bancário'},
  { value: EnumTypePaymentMethod.CARTAO, text: 'Cartão de Crédito'},
  { value: EnumTypePaymentMethod.PIX, text: 'PIX'},
  { value: EnumTypePaymentMethod.OUTROS, text: 'Outros'},
]

export const EnumStatusTracking = {NaoEncontrado: 0,Atrasado: 1,Problema: 2,EmTransporte: 3,EntregueAoCliente: 4,TentativaDeEntrega1: 5,TentativaDeEntrega2: 6,AguardandoRetirada: 7,RetornandoAoRemetente: 8,Extraviados: 9,EntregueAoRemetente: 10,Novo: 11,ExtraviadoEncerrado: 12,AguardandoRetiradaEncerrado: 13}
export const EnumStatusTrackingValues = [
  { value: EnumStatusTracking.NaoEncontrado, text: 'Não Encontrado' },
  { value: EnumStatusTracking.Atrasado, text: 'Atrasado' },
  { value: EnumStatusTracking.Problema, text: 'Problema' },
  { value: EnumStatusTracking.EmTransporte, text: 'Em Transporte' },
  { value: EnumStatusTracking.EntregueAoCliente, text: 'Entregue' },
  { value: EnumStatusTracking.TentativaDeEntrega1, text: 'Tentativa De Entrega 1' },
  { value: EnumStatusTracking.TentativaDeEntrega2, text: 'Tentativa De Entrega 2' },
  { value: EnumStatusTracking.AguardandoRetirada, text: 'Aguardando Retirada' },
  { value: EnumStatusTracking.RetornandoAoRemetente, text: 'Retornando ao remetente' },
  { value: EnumStatusTracking.Extraviados, text: 'Extraviados' },
  { value: EnumStatusTracking.EntregueAoRemetente, text: 'Entregue ao remetente' },
  { value: EnumStatusTracking.Novo, text: 'Novo' },
  { value: EnumStatusTracking.ExtraviadoEncerrado, text: 'Extraviado Encerrado' },
  { value: EnumStatusTracking.AguardandoRetiradaEncerrado, text: 'Aguardando Retirada Encerrado' }
]

export const EnumCarrier = {CORREIOS: 0, MARKETPLACE: 1, MERCADOLIVRE: 2, MANDAE: 3, ARQUIVO:4, TRANSPORTADORA:5, MAGALU:6, UELLO:7}
export const EnumCarrierValues = [
  { value: EnumCarrier.CORREIOS, text: 'Correios'},
  { value: EnumCarrier.MARKETPLACE, text: 'Marketplace'},
  { value: EnumCarrier.MERCADOLIVRE, text: 'Mercado Livre'},
  { value: EnumCarrier.MANDAE, text: 'Mandaê'},
  { value: EnumCarrier.ARQUIVO, text: 'Customizado'},
  { value: EnumCarrier.TRANSPORTADORA, text: 'Transportadora'},
  { value: EnumCarrier.MAGALU, text: 'Magalu'},
  { value: EnumCarrier.UELLO, text: 'Uello'},
]

export const EnumFullPrint = {ERRO: 0, PENDENTE: 1,CONFIRMADO: 2,IMPRIMINDO: 3,FINALIZADO: 4,CANCELADO: 5,REJEITADO: 12,SAIUENTREGA: 8,CONCLUIDO: 9, ACABAMENTO: 19}
export const EnumFullPrintValues = [
  { value: EnumFullPrint.ERRO, text: 'Novo'},
  { value: EnumFullPrint.PENDENTE, text: 'Aguardando Confirmação'},
  { value: EnumFullPrint.CONFIRMADO, text: 'Confirmado'},
  { value: EnumFullPrint.IMPRIMINDO, text: 'Imprimindo'},
  { value: EnumFullPrint.FINALIZADO, text: 'Finalizado'},
  { value: EnumFullPrint.CANCELADO, text: 'Cancelado'},
  { value: EnumFullPrint.REJEITADO, text: 'Rejeitado'},
  { value: EnumFullPrint.SAIUENTREGA, text: 'Saiu Entrega'},
  { value: EnumFullPrint.CONCLUIDO, text: 'Concluído'},
  { value: EnumFullPrint.ACABAMENTO, text: 'Acabamento'}
]

export const EnumStatusProduct = {DESABILITADO: 0, HABILITADO: 1}
export const EnumStatusProductValues = [
  { value: EnumStatusProduct.DESABILITADO, text: 'Desabilitado'},
  { value: EnumStatusProduct.HABILITADO, text: 'Habilitado'},
]

export const EnumShipmentStatusDevolution = { PENDENTE: 0, CONFIRMADO: 1, SEPARANDO: 3}
export const EnumShipmentStatusDevolutionValues = [
  { value: EnumShipmentStatusDevolution.PENDENTE, text: 'Pendente'},
  { value: EnumShipmentStatusDevolution.CONFIRMADO, text: 'Confirmado'},
  { value: EnumShipmentStatusDevolution.SEPARANDO, text: 'Em Devolução'},
]

export const EnumTypeInvoiceOperation = { SALE: 0, DEVOLUTION: 1, PURCHASE: 2, TRANSFER: 3, IMPORT: 4, CONSIGNMENT: 5, SHIPPING:6, BONUS: 7 }
export const EnumTypeInvoiceOperationValues = [
  { value: EnumTypeInvoiceOperation.SALE, text: 'Venda'},
  { value: EnumTypeInvoiceOperation.DEVOLUTION, text: 'Devolução'},
  { value: EnumTypeInvoiceOperation.PURCHASE, text: 'Compra'},
  { value: EnumTypeInvoiceOperation.TRANSFER, text: 'Tranferência'},
  { value: EnumTypeInvoiceOperation.IMPORT, text: 'Importação'},
  { value: EnumTypeInvoiceOperation.CONSIGNMENT, text: 'Consignação'},
  { value: EnumTypeInvoiceOperation.SHIPPING, text: 'Remessa'},
  { value: EnumTypeInvoiceOperation.BONUS, text: 'Bonificação'},
]

export const EnumIssuer = { NFEONLINE: 'nfeonline', OMIE: 'omie'}
export const EnumIssuerValues = [
  { value: EnumIssuer.NFEONLINE, text: 'NFE Online'},
  { value: EnumIssuer.OMIE, text: 'Omie'},
]



export const EnumPremidia = {ERROR: 'error', PENDING: 'pending',CONFIRMED: 'confirmed',PRINTING: 'printing',FINISHED: 'finished',CANCELED: 'canceled'}
export const EnumPremidiaValues = [
  { value: EnumPremidia.ERROR, text: 'Erro'},
  { value: EnumPremidia.PENDING, text: 'Pendente'},
  { value: EnumPremidia.CONFIRMED, text: 'Confirmado'},
  { value: EnumPremidia.PRINTING, text: 'Imprimindo'},
  { value: EnumPremidia.FINISHED, text: 'Finalizado'},
  { value: EnumPremidia.CANCELED, text: 'Cancelado'},
]
